import React from "react"
import tw from "twin.macro"
import queryString from "query-string"

import Layout from "@src/components/layout"
import CustomContainer from "@src/components/customContainer"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import { getToken } from "@src/utils/auth"
import { Localization } from "@src/localization"
import { PublicClientApplication} from "@azure/msal-browser";
import { MsalProvider} from "@azure/msal-react";
import { msalConfig } from "@src/msalConfig";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Logout = (): React.ReactElement => {
  const headerContent = React.useContext(Localization).header

  // parse url for query params
  const parsed = queryString.parse(
    typeof window !== `undefined` ? window.location.search : ""
  )

  let message = "You have been successfully logged out!"
  if (parsed && parsed.error && parsed.error === "401") {
    message = "It seems like you are not logged in."
  }

  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <MsalProvider instance={msalInstance}>
    <Layout>
      <CustomContainer customCss={tw`bg-white`}>
        <div css={[tw`flex flex-col w-full items-center pt-8 pb-4`]}>
          {message}
        </div>
        <div tw="flex justify-center pb-8">
          <a
            href={`${process.env.GATSBY_DASHBOARDV3}/web/CombRemPreService.aspx?SessionID=${getToken()}`}
            tw="flex items-center text-sm text-gray-700 leading-4 cursor-pointer"
          >
            <CustomButton color={ButtonColors.orange}>
              {headerContent.backToDashboard}
            </CustomButton>
          </a>
        </div>
      </CustomContainer>
    </Layout>
    </MsalProvider>
  )
}

export default Logout
